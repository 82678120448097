import React, { useEffect } from 'react'
import styled from 'styled-components'

const CommentoStyled = styled.div`
  font-family: ${({ theme }) => theme.typography.headerFontFamily} !important;

  #commento-submit-button-root {
    background: ${({ theme }) => theme.palette.yellow.dark1};
    color: ${({ theme }) => theme.palette.text['0']};
    border-radius: 50px;
    box-shadow: none;
    padding: 0.5em 1em;
    text-transform: uppercase;
    cursor: pointer;
    transition-duration: 0.25s;
    :hover {
      transform: scale(1.1);
      background-color: ${({ theme }) => theme.palette.yellow['0']};
    }
  }

  #commento-footer {
    display: none;
  }
`

// Helper to add scripts to our page
const insertScript = ({ src, id, pageId, parentElement }) => {
  const script = window.document.createElement('script')
  script.async = true
  script.src = src
  script.id = id
  script.setAttribute('data-no-fonts', 'true')
  script.setAttribute('data-page-id', pageId)
  parentElement.appendChild(script)
  return script
}
// Helper to remove scripts from our page
const removeScript = (id, parentElement) => {
  const script = window.document.getElementById(id)
  if (script) {
    parentElement.removeChild(script)
  }
}
// The actual component
const Commento = ({ id }) => {
  useEffect(() => {
    // If there's no window there's nothing to do for us
    if (!window) {
      return
    }
    const document = window.document
    // In case our #commento container exists we can add our commento script
    if (document.getElementById('commento')) {
      insertScript({
        src: `https://cdn.commento.io/js/commento.js`,
        id: `commento-script`,
        pageId: id,
        parentElement: document.body,
        cssUrl: '/commento.css',
      })
    }
    // Cleanup; remove the script from the page
    return () => removeScript(`commento-script`, document.body)
  }, [id])
  return <CommentoStyled id={`commento`} />
}
export default Commento
