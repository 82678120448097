import React, { useContext } from 'react'
import Box from '@local/shared/components/System/Box'
import styled, { ThemeContext } from 'styled-components'

const Root = styled('span')`
  vertical-align: middle;
  position: relative;
  a {
    float: left;
  }

  @media (hover: hover) {
    a {
      opacity: 0;
    }
  }

  :hover a {
    opacity: 100%;
  }
`

const Heading = ({ children, component, slugger }) => {
  const theme = useContext(ThemeContext)
  const Component = component
  const isChildrenString =
    typeof children === 'string' || children instanceof String
  const anchor = (isChildrenString && slugger && slugger.slug(children)) || null

  let anchorProps = {}
  if (anchor) {
    anchorProps = { id: anchor, name: anchor }
  }
  return (
    <Root>
      <Component>
        <Box
          display={'flex'}
          alignItems={'flex-start'}
          position={'relative'}
          left={'-1em'}
        >
          <Box
            as={'a'}
            href={`#${anchor}`}
            style={{ textDecoration: 'none' }}
            {...anchorProps}
            pr={'0.25em'}
          >
            <Box
              width={'0.75em'}
              as={'svg'}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 97.85 84.3"
            >
              <title>{children} Link</title>
              <path
                fill={theme.palette.text['0']}
                d="M65.5,58.5,92.9,31.1a16.78,16.78,0,0,0,0-23.8L90.5,4.9A16.63,16.63,0,0,0,78.6,0,17.05,17.05,0,0,0,66.7,4.9L54.3,17.3a19.82,19.82,0,0,1,3.1,1.9L69.2,7.4a13.33,13.33,0,0,1,9.4-3.9A13.15,13.15,0,0,1,88,7.4l2.4,2.4a13.28,13.28,0,0,1,0,18.8L63,56a13.33,13.33,0,0,1-9.4,3.9A13.15,13.15,0,0,1,44.2,56l-2.4-2.4a13.28,13.28,0,0,1,0-18.8L46,30.6a7.47,7.47,0,0,0-6.3,1.3l-.4.4a16.78,16.78,0,0,0,0,23.8l2.4,2.4a16.63,16.63,0,0,0,11.9,4.9A17.05,17.05,0,0,0,65.5,58.5Z"
              />
              <path
                fill={theme.palette.text['0']}
                d="M0,65.1A16.63,16.63,0,0,0,4.9,77l2.4,2.4a16.63,16.63,0,0,0,11.9,4.9h0a17.05,17.05,0,0,0,11.9-4.9L43.2,67.3l-.9-.9-1.6-1.6-.3.3L28.6,76.9a13.33,13.33,0,0,1-9.4,3.9h0a13.33,13.33,0,0,1-9.4-3.9L7.4,74.5a13.28,13.28,0,0,1,0-18.8L34.8,28.3a5.55,5.55,0,0,1,.8-.6,13.14,13.14,0,0,1,11.3-3,11.25,11.25,0,0,1,3.6,1.4,13.43,13.43,0,0,1,3.1,2.2l1.5,1.5.9.9a13.28,13.28,0,0,1,0,18.8l-2.1,2.1-2,2,.2.2,2.3,2.3,3.3-3.3.4-.4.5-.5A16.63,16.63,0,0,0,63.5,40a17.05,17.05,0,0,0-4.9-11.9l-.9-.9-1.5-1.5a13.31,13.31,0,0,0-3-2.3,15,15,0,0,0-3.4-1.6,17.38,17.38,0,0,0-7.5-.8,17,17,0,0,0-9.9,4.7L5,53.1A17.13,17.13,0,0,0,0,65.1Z"
              />
            </Box>
          </Box>
          <Box display={'inline-block'} flex={'1'}>
            {children}
          </Box>
        </Box>
      </Component>
    </Root>
  )
}

export default Heading
