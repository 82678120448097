import React from 'react'
import Box from '@local/shared/components/System/Box'
import styled from 'styled-components'

const Root = styled(Box)`
  margin-bottom: 1em;
  & > * {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
`

const BlogItemWrapper = ({ children, ...props }) => {
  return (
    <Root borderRadius={'10px'} {...props}>
      {children}
    </Root>
  )
}

export default BlogItemWrapper
