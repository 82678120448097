import React from 'react'
import Box from '@local/shared/components/System/Box'
import Typography from '@local/shared/components/System/Typography'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const DictionaryDefinition = ({
  word,
  pronunciation,
  wordType,
  children,
  sourceName,
  sourceLink,
}) => {
  return (
    <Box
      px={'1em'}
      py={'1em'}
      my={'1em'}
      bgcolor={'yellow.light1'}
      borderRadius={'5px'}
    >
      <Typography variant={'h4'} mt={'0 !important'} mb={'0.5em'}>
        {word}
      </Typography>
      <Typography as={'p'} variant={'h6'} scale={-1} mb={'0.5em'}>
        {pronunciation}
      </Typography>
      <Typography scale={1} mb={'1em'}>
        <em>{wordType}</em>
      </Typography>
      <Typography mb={'1em'} ml={'1em'}>
        {children}
      </Typography>
      {sourceName && (
        <Typography scale={-3}>
          Source:{' '}
          <OutboundLink href={sourceLink}>Cambridge Dictionary</OutboundLink>
        </Typography>
      )}
    </Box>
  )
}

export default DictionaryDefinition
