import React from 'react'
import Box from '@local/shared/components/System/Box'
import styled from 'styled-components'
import Typography from '@local/shared/components/System/Typography'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const StyledBlogImg = styled(Box)`
  .gatsby-image-wrapper {
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  }
`

const BlogImg = ({ children, caption, credits, publicUrl }) => {
  return (
    <StyledBlogImg my={'1em'}>
      {credits && (
        <Typography
          textAlign={'right'}
          color={'text.placeholder'}
          variant={'p'}
          scale={-2}
          my={'1em'}
          css={{ fontStyle: 'italic' }}
        >
          Credit:
          {credits.map((c, i) => (
            <React.Fragment key={i}>
              {' '}
              <OutboundLink href={c.link}>{c.text}</OutboundLink>
            </React.Fragment>
          ))}
        </Typography>
      )}
      <OutboundLink href={publicUrl}>{children}</OutboundLink>
      {caption && (
        <Typography
          textAlign={'center'}
          color={'text.placeholder'}
          variant={'p'}
          my={'1em'}
          css={{ fontStyle: 'italic' }}
        >
          {caption}
        </Typography>
      )}
    </StyledBlogImg>
  )
}

export default BlogImg
