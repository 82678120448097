import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import PageLayout from 'src/components/PageLayout'
import Typography from '@local/shared/components/System/Typography'
import Box from '@local/shared/components/System/Box'
import { ThemeContext } from 'styled-components'
import { Location } from '@reach/router'
import BlogContact from 'src/components/BlogContact'
import BlogPostBox from 'src/components/BlogPostBox'
import BlogImg from 'src/components/BlogImg'
import Img from 'gatsby-image'
import Seo from 'src/components/Seo'
import DictionaryDefinition from 'src/components/DictionaryDefinition'
import Commento from 'src/components/Commento'
import BlogBox from 'src/components/BlogBox'
import Table from 'src/components/Table'
import YouTubeVideo from 'src/components/YouTubeVideo'
import BlogItemWrapper from 'src/components/BlogItemWrapper'
import GithubSlugger from 'github-slugger'
import Heading from 'src/components/Heading'
import wildlifeFlowerIconImg from 'src/images/wildlife-flower-icon.svg'

function BlogPostTemplate(props) {
  const slugger = new GithubSlugger()
  const theme = useContext(ThemeContext)
  const post = props.data.mdx

  const makeHeadingComponent = variant => ({ children }) => (
    <Heading
      slugger={slugger}
      component={({ children }) => (
        <Typography variant={variant}>{children}</Typography>
      )}
    >
      {children}
    </Heading>
  )

  const components = {
    BlogItemWrapper: BlogItemWrapper,
    YouTubeVideo,
    DictionaryDefinition,
    Typography,
    h1: theme.typography.h1.component,
    h2: makeHeadingComponent('h2'),
    h3: makeHeadingComponent('h3'),
    h4: makeHeadingComponent('h4'),
    h5: makeHeadingComponent('h5'),
    h6: makeHeadingComponent('h6'),
    hr: () => (
      <Box
        position={'relative'}
        my={'2em'}
        height={`${1 / 16}em`}
        bgcolor={'text.blockquote'}
      >
        <Box
          mx={'auto'}
          width={1}
          textAlign={'center'}
          position={'relative'}
          top={'-0.5em'}
        >
          <Box bgcolor={'white'} px={'1em'} display={'inline-block'}>
            <Box as={'img'} src={wildlifeFlowerIconImg} height={'1em'} />
          </Box>
        </Box>
      </Box>
    ),
    p: ({ children }) => (
      <Typography as={'p'} variant={'body'} mb={'0'}>
        {children}
      </Typography>
    ),
    table: Table,
    li: ({ children }) => (
      <Typography as={'li'} variant={'body'} mb={'0'}>
        {children}
      </Typography>
    ),
  }

  const images = {}
  if (post.frontmatter.images) {
    post.frontmatter.images.forEach(image => {
      const { name, caption, path, credits, extension, css } = image
      const { childImageSharp, publicURL } = path
      console.log('image', image)
      if (childImageSharp) {
        const { fluid } = childImageSharp
        images[name] = () => (
          <BlogImg
            caption={caption}
            credits={credits}
            publicUrl={publicURL}
            {...css}
          >
            <Img fluid={fluid} />
          </BlogImg>
        )
      } else {
        images[name] = () => (
          <BlogImg
            caption={caption}
            credits={credits}
            publicUrl={publicURL}
            {...css}
          >
            <img src={publicURL} />
          </BlogImg>
        )
      }
    })
  }
  return (
    <PageLayout>
      <Location>
        {({ location }) => (
          <>
            <Seo
              title={post.frontmatter.title}
              description={post.frontmatter.description}
              shortTeaser={post.frontmatter.shortTeaser}
              article={{
                createdDatetime: post.frontmatter.createdDatetime,
                modifiedDatetime: post.frontmatter.modifiedDatetime,
              }}
              locationPathname={location.pathname}
            />
            <Box
              width={1}
              py={'1em'}
              md={{ py: '4em', bgcolor: 'blue.0' }}
              borderBottom={'solid 0.5em'}
              borderColor={'blue.light0'}
            >
              <BlogPostBox
                slug={post.fields.slug}
                title={post.frontmatter.title}
                date={post.frontmatter.created}
              >
                <MDXProvider components={components}>
                  <MDXRenderer images={images}>{post.body}</MDXRenderer>
                </MDXProvider>
              </BlogPostBox>
              <BlogContact />
              <BlogBox>
                <Commento id={`blog/${post.frontmatter.id}`} />
              </BlogBox>
            </Box>
          </>
        )}
      </Location>
    </PageLayout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      fields {
        slug
      }
      frontmatter {
        id
        title
        description
        shortTeaser
        created(formatString: "MMMM DD, YYYY")
        createdDatetime: created
        modifiedDatetime: modified
        images {
          name
          caption
          credits {
            text
            link
          }
          path {
            publicURL
            extension
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
