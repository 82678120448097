import React from 'react'
import styled from 'styled-components'
import Box from '@local/shared/components/System/Box'

const TableStyled = styled.table`
  font-family: ${({ theme }) => theme.typography.headerFontFamily};
  border-spacing: 0;
  text-align: left;

  thead th {
    border-bottom: 0.175em solid ${({ theme }) => theme.palette.blue.light1};
  }

  th,
  td {
    padding: 0 0.5em 0.5em 0;
  }

  tbody tr:first-child td,
  tbody tr:first-child th {
    padding-top: 0.5em;
  }

  tbody th {
    font-weight: bold;
  }
`

const Table = props => (
  <Box as={TableStyled} mx={'auto'} my={'1em'} {...props}>
    {props.children}
  </Box>
)

export default Table
