import React from 'react'
import Box from '@local/shared/components/System/Box'
import styled from 'styled-components'

const Root = styled(Box)`
  position: relative;
  padding-bottom: ${({ vWidth, vHeight }) => (vHeight / vWidth) * 100}%;
  height: 0;
  margin-bottom: 1em;
  border-radius: 10px;
  overflow: hidden;
  & iframe {
    position: absolute;
    top: -1%;
    left: 0;
    width: 101%;
    height: 101%;
  }
`

const YouTubeVideo = ({ id, params }) => {
  console.log(params)
  const paramsJoined = params && params.join('&')
  const width = 560
  const height = 315
  return (
    <Root vWidth={width} vHeight={height}>
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${id}?${paramsJoined}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Root>
  )
}

export default YouTubeVideo
